<template>
  <div class="v_account_complete">
    <head-back>
        <template v-slot:returnText>
        <span>返回</span>
      </template>

      <template v-slot:default>
        <span>账号完成率</span>
      </template>
    </head-back>
    <div class="v-account-complete-container">
      <div class="v-account-complete-card">
        <div class="v-account-complete-card-top g-flex-align-center">
          <img :src="userInfo.head_img" alt="" srcset="">
          <div class="v-account-complete-card-top-right">
            <p class="v-account-complete-card-top-right-username">{{userInfo.nick_name}}</p>
            <span class="v-account-complete-card-top-right-num">完成率：{{userInfo.reach_rate}}%</span>
          </div>
        </div>
        <div class="v-account-complete-card-bottom">
          <van-progress :percentage="Number(userInfo.reach_rate)" stroke-width="6" color="#FA436A"/>
        </div>
      </div>

      <p class="v-account-complete-tips">
        取消一笔订单则扣1%的完成率，完成一笔订单，则增加5%的完成率，完成率最高100%。
      </p>
    </div>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  export default {
    data() {
      return {
        userInfo: this.$global.userInfo,
      }
    },
    components: { HeadBack },
    
  }
</script>

<style lang="scss">
.v_account_complete {
  height: 100%;
  background: #F1F1F1;
  .v-account-complete-container {
    margin-top: 10px;
    .v-account-complete-card {
      background: $white;
      padding: 18px 24px;
      .v-account-complete-card-top {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
        .v-account-complete-card-top-right {
          padding-left: 16px;
          font-size: 14px;
          color: #292929;
          .v-account-complete-card-top-right-username {
            padding-bottom: 4px;
          }
        }
      }
      .v-account-complete-card-bottom {
        padding-top: 26px;
        .van-progress {
          width: 100%;
          .van-progress__portion {
            width: 100%;
          }
        }
      }
    }

    .v-account-complete-tips {
      font-size: 13px;
      color: #292929;
      line-height: 20px;
      padding: 10px 18px;
    }
  }
}
</style>